var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{attrs:{"layout":"horizontal","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"form":_vm.formObj},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(_vm.isGeneralTicket)?_c('a-form-item',_vm._b({},'a-form-item',_vm.wrapperOnlyLayout,false),[_vm._v("\n\t\t"+_vm._s(_vm.$t('ticket.field.general_ticket.help'))+"\n\t")]):_c('a-form-item',{attrs:{"label":_vm.$t('ticket.field.drone')}},[_vm._v("\n\t\t"+_vm._s(_vm.cDroneObj.name)+"\n\t")]),_vm._v(" "),(_vm.isGeneralTicket && _vm.isUpdate)?_c('a-form-item',{attrs:{"label":_vm.$t('ticket.field.drone'),"extra":_vm.$t('ticket.field.drone.help')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['drone_serial_no']),expression:"['drone_serial_no']"}],attrs:{"placeholder":_vm.$t('ticket.field.drone.placeholder')}})],1):_vm._e(),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('ticket.field.report_by'),"extra":_vm.$t('ticket.field.report_by.help'),"required":true}},[_c('UserSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:(['report_by_id' ,
				{
					rules: [
						{ validator: _vm.validateReportByData },
					],
				}]),expression:"['report_by_id' ,\n\t\t\t\t{\n\t\t\t\t\trules: [\n\t\t\t\t\t\t{ validator: validateReportByData },\n\t\t\t\t\t],\n\t\t\t\t}]"}],attrs:{"placeholder":_vm.$t('ticket.field.report_by.placeholder'),"select-mode":"ticket_reporter"}})],1),_vm._v(" "),_c('hr'),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('ticket.field.event.date'),"extra":_vm.$t('ticket.field.event.date.help')}},[_c('MyDatePicker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['event_datetime',
				{
					rules: [
						{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','ticket.field.event.date') },
					] ,
				}]),expression:"['event_datetime',\n\t\t\t\t{\n\t\t\t\t\trules: [\n\t\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','ticket.field.event.date') },\n\t\t\t\t\t] ,\n\t\t\t\t}]"}],attrs:{"format":"DD MMMM YYYY"}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('ticket.field.event.time'),"extra":_vm.$t('ticket.field.event.time.help')}},[_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['event_time',
				{
					rules: [
						{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','ticket.field.event.time') },
					] ,
				}]),expression:"['event_time',\n\t\t\t\t{\n\t\t\t\t\trules: [\n\t\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','ticket.field.event.time') },\n\t\t\t\t\t] ,\n\t\t\t\t}]"}],attrs:{"input-read-only":"","format":"HH:mm","value-format":"HH:mm"}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('ticket.field.problem_type'),"extra":_vm.$t('ticket.field.problem_type.help')}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
			'problem_type' ,
			{
				rules: [
					{ required: true, message: _vm.$tt('validate.required','ticket.field.problem_type') },
				],
				initialValue: 'general',
			},
			]),expression:"[\n\t\t\t'problem_type' ,\n\t\t\t{\n\t\t\t\trules: [\n\t\t\t\t\t{ required: true, message: $tt('validate.required','ticket.field.problem_type') },\n\t\t\t\t],\n\t\t\t\tinitialValue: 'general',\n\t\t\t},\n\t\t\t]"}],attrs:{"options":_vm.problemTypeOptions}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('ticket.field.ticket_types'),"extra":_vm.$t('ticket.field.ticket_types.help')}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['ticket_types',{
					rules: [
						{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','ticket.field.ticket_types'), type: 'array'  },
					] ,
					initialValue: ['hardware'],
			}]),expression:"['ticket_types',{\n\t\t\t\t\trules: [\n\t\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','ticket.field.ticket_types'), type: 'array'  },\n\t\t\t\t\t] ,\n\t\t\t\t\tinitialValue: ['hardware'],\n\t\t\t}]"}],attrs:{"options":_vm.ticketTypeOptions}})],1),_vm._v(" "),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isGeneralTicket),expression:"!isGeneralTicket"}],attrs:{"label":_vm.$t('ticket.field.location_type'),"extra":_vm.$t('ticket.field.location_type.help')}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
			'location_type' ,
			{
				rules: [
					{ required: true, message: _vm.$tt('validate.required','ticket.field.location_type') },
				],
				initialValue: 'customer',
			},
			]),expression:"[\n\t\t\t'location_type' ,\n\t\t\t{\n\t\t\t\trules: [\n\t\t\t\t\t{ required: true, message: $tt('validate.required','ticket.field.location_type') },\n\t\t\t\t],\n\t\t\t\tinitialValue: 'customer',\n\t\t\t},\n\t\t\t]"}],attrs:{"options":_vm.locationTypeOptions}})],1),_vm._v(" "),_c('hr'),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('ticket.field.topic'),"extra":_vm.$t('ticket.field.topic.help')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
				'topic' ,
				{
					rules: [
						{ required: true, message: _vm.$tt('validate.required','ticket.field.topic') },
					],
				},
			]),expression:"[\n\t\t\t\t'topic' ,\n\t\t\t\t{\n\t\t\t\t\trules: [\n\t\t\t\t\t\t{ required: true, message: $tt('validate.required','ticket.field.topic') },\n\t\t\t\t\t],\n\t\t\t\t},\n\t\t\t]"}],attrs:{"auto-size":{ minRows: 2,maxRows: 5 }}})],1),_vm._v(" "),(_vm.isCreate)?_c('a-form-item',{attrs:{"label":_vm.$t('ticket.field.description'),"required":true,"validate-status":_vm.commentValidate.status,"help":_vm.commentValidate.message}},[_c('TicketCommentInput',{ref:"commentInputRef",on:{"change":_vm.handleComment}})],1):_vm._e(),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('ticket.field.search_tags'),"extra":_vm.$t('ticket.field.search_tags.help')}},[_c('MyTagsInputAutoComplete',{attrs:{"auto-complete-list":_vm.cTagsAutoComplete,"tag-css-class":"mytag-info"},model:{value:(_vm.searchTags),callback:function ($$v) {_vm.searchTags=$$v},expression:"searchTags"}})],1),_vm._v(" "),_c('hr'),_vm._v(" "),_c('a-form-item',_vm._b({},'a-form-item',_vm.wrapperOnlyLayout,false),[_vm._t("submitSlot",function(){return [_c('a-button',{staticClass:"btn-submit",attrs:{"loading":_vm.loading,"type":"info","html-type":"submit","size":"large"}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('common.save'))+"\n\t\t\t")])]},{"formLoading":_vm.loading})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }