<i18n locale="th" lang="yaml" >
empty.default : "พิมพ์เพื่อหาข้อมูล"
</i18n>

<template>
	<div class="search-empty">
		<a-icon type="search"  class="search-empty-icon" />
		<div>{{cMessage}}</div>
	</div>
</template>

<script>
export default {
	props : {
		message : {
			type : String ,
			default : undefined
		}
	} ,
	computed : {
		cMessage() {
			return this.message ? this.message : this.$t('empty.default')
		}
	}
}
</script>

<style lang="less" scoped>
.search-empty {
	text-align: center;
	padding : 12px 0;
}
.search-empty-icon {
	font-size : 1.5em;
}
</style>
