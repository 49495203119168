<i18n locale="en" lang="yaml">
myselect.loading.default : "Loading ..."
</i18n>
<i18n locale="th" lang="yaml" >
myselect.loading.default : "กำลังค้นหาข้อมูล ..."
</i18n>

<template>
	<a-config-provider>
		<SearchEmpty slot="renderEmpty" :message="emptyMessage"/>
		<a-select ref="selectRef"
			v-bind="$attrs" allow-clear
			show-search
			:filter-option="false"
			class="myselect"
			:dropdown-class-name="cDropdownClassName"
			:get-popup-container="getPopupContainer"
			@change="selectChange"
			@search="debounceSearch">
			<div slot="dropdownRender" slot-scope="menu" >
				<slot name="customTop" />
				<VNodes :vnodes="menu" />
				<div v-show="hasPaging" class="myselect-pagination" @mousedown.prevent>
					<a-button type="link" icon="left"
						:disabled="pagination.current == 1"
						@click="changePage($event,-1)">
					</a-button>
					<div class="myselect-pagination-content">
						<span v-show="!loading">
							{{pagination.current}} / {{cPageTotalPage}}
						</span>
						<span v-show="loading">
							{{cLoadingMessage}}
						</span>
					</div>
					<a-button type="link" icon="right"
						:disabled="pagination.current == cPageTotalPage"
						@click="changePage($event,1)">
					</a-button>
				</div>
			</div>
			<slot name="default" />
		</a-select>
	</a-config-provider>
</template>

<script>
import {Select,ConfigProvider} from "ant-design-vue"
import debounce from "lodash/debounce"
import SearchEmpty from "@components/common/SearchEmpty.vue"
import PopupMixin from "@mixins/PopupMixin.vue"
export default {
	components : {
		"a-select" : Select, "a-config-provider" : ConfigProvider,
		SearchEmpty ,
		VNodes : {
			functional : true,
			render: (h,ctx) => ctx.props.vnodes,
		}
	},
	mixins : [PopupMixin] ,
	props : {
		initialEmptySearch : {
			type : Boolean,
			default : true ,
		} ,
		emptyMessage : {
			type : String,
			default : undefined
		} ,
		loadingMessage : {
			type : String,
			default : undefined
		} ,
		loading : {
			type : Boolean,
			default : false
		} ,
		noMaxHeight : {
			type : Boolean,
			default : false,
		} ,
		dropdownClassName : {
			type : String,
			default : undefined,
		} ,
		pagePosition : {
			type : String,
			default : 'top'
		} ,
		pagination : {
			type : null,
			default(){
				return {
					total : 0,
					pageSize : 5 ,
					current : 1,
					hideOnSinglePage : true,
					onChange : page => {
						this.searchPage(page)
					}
				}
			}
		}
	} ,
	data(){
		return {
			currentSearch : '' ,
		}
	} ,
	computed : {
		hasPaging() {
			return this.pagination && this.pagination.total && this.pagination.total > this.pagination.pageSize
		} ,
		cPageTotalPage() {
			return Math.ceil(this.pagination.total/this.pagination.pageSize)
		} ,
		cLoadingMessage() {
			return this.loadingMessage ? this.loadingMessage : this.$t('myselect.loading.default')
		} ,
		cDropdownClassName() {
			let css = "myselect-dropdown"
			if (this.$notEmpty(this.dropdownClassName)) {
				css += " "+this.dropdownClassName
			}
			if (this.pagePosition == 'bottom') {
				css += " myselect-dropdown-page-bottom"
			}
			if (this.noMaxHeight) {
				css += " no-max-height"
			}
			return css
		}
	} ,
	mounted() {
		if (this.initialEmptySearch) {
			this.$nextTick(()=>{
				this.search('')
			})
		}
	} ,
	created() {
		this.debounceSearch = debounce(this.search,200)
	} ,
	methods : {
		selectChange(value) {
			if (!value) {
				// clear search
				this.search('')
				this.$emit("clear-search")
			}
			this.$emit('change',value)
		} ,
		changePage(event,page) {
			this.searchPage(this.pagination.current + page)
		} ,
		searchPage(page) {
			this.$emit('search',{
					search : this.currentSearch,
					page : page
			});
		} ,
		search(searchTerm) {
			this.currentSearch = searchTerm
			this.$emit('search',{
					search : searchTerm,
					page : 1
			});
		} ,
		focus() {
			this.$refs.selectRef.focus()
		} ,
		blur() {
			this.$refs.selectRef.blur()
		}
	}
}
</script>

<style lang="less">
.myselect-dropdown {
	.ant-select-dropdown-menu {
		max-height: none;
	}
	.ant-select-dropdown-content {
		max-height: 300px;
		display : flex;
		flex-direction: column;
		.custom_scrollbar();
	}
	&.myselect-dropdown-page-bottom .ant-select-dropdown-content {
		flex-direction: column-reverse;
	}

	&.no-max-height {
		.ant-select-dropdown-content {
			max-height: none;
		}
	}
}
</style>
<style lang="less" scoped>
.myselect-pagination {
	display: flex;
	align-content : center;
  border-top: 1px solid @border-color-base;

	.myselect-pagination-content {
		align-self: center;
		flex-grow: 1;
		text-align: center;
	}
}
</style>
